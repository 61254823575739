import { SET_PLAYING_CONTENT, SET_PLAYER_LEFT_SIDE_STATE } from "../actions/appActions";

const DEFAULT_STATE = {
    playigContent: false,
    playerLeftSideState: "OPEN" // OPEN, CLOSED
};

const appReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {

        case SET_PLAYING_CONTENT:
            return {
                ...state,
                playigContent: action.payload
            }

        case SET_PLAYER_LEFT_SIDE_STATE:
            return {
                ...state,
                playerLeftSideState: action.payload
            }

        default:
            return state;
    }
}

export default appReducer;