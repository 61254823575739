import React from "react";
import { useParams } from "react-router";

export function PresentationComponent() {


    let { url } = useParams();


    return <div className="bg-gray-800 h-screen">

        <div style={{ width: "100%" }} >
        {/* style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;" */}
            <div className="flex justify-center py-4" >
            {/* style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" */}
                <iframe  title="presentation" frameborder="0" width="1200px" height="675px"
                    
                    src={`https://view.genial.ly/${url}`} type="text/html" allowscriptaccess="always"
                    allowfullscreen="true" scrolling="yes" allownetworking="all">
                </iframe>
            </div>
        </div>


    </div>
}