import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import correctSound from '../../assets/sounds/correct.mp3';
import incorrectSound from '../../assets/sounds/incorrect.mp3';

interface Question {
  question: string;
  options: string[];
  correctOption: string;
}

const useStyles = makeStyles((theme) => ({
  millionaireQuiz: {
    // maxWidth: 400,
    margin: 'auto',
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: 10,
    backgroundColor: '#2c3e50', // Fondo del pizarrón
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    color: '#ecf0f1', // Color del texto
  },
  questionContainer: {
    textAlign: 'center',
    marginBottom: 20,
  },
  questionText: {
    fontSize: '24px', // Tamaño de fuente más grande
    lineHeight: '1.5', // Espaciado de línea para mejorar la legibilidad
    fontFamily: 'Chalkduster, fantasy', // Fuente que parece escrita a mano
    transition: 'color 0.3s ease',
  },
  option: {
    cursor: 'pointer',
    padding: "8px",
    backgroundColor: '#3498db', // Color de la tiza
    color: '#ecf0f1', // Color del texto
    border: '1px solid #2980b9',
    borderRadius: 5,
    transition: 'transform 0.2s ease', // Agregamos transición a la transformación al hacer hover
    '&:hover': {
      backgroundColor: '#2980b9',
      transform: 'scale(1.05)', // Efecto de escala al hacer hover
    },
  },
  resultContainer: {
    textAlign: 'center',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  resetButton: {
    cursor: 'pointer',
    padding: 10,
    marginTop: 10,
    backgroundColor: '#e74c3c', // Color del botón Reiniciar
    color: '#ecf0f1', // Color del texto
    border: 'none',
    borderRadius: 5,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#c0392b',
    },
  },
  correct: {
    backgroundColor: '#27ae60', // Color de fondo para respuesta correcta
    color: '#ecf0f1', // Color del texto para respuesta correcta
    position: 'relative',
  },
  incorrect: {
    backgroundColor: '#e74c3c', // Color de fondo para respuesta incorrecta
    color: '#ecf0f1', // Color del texto para respuesta incorrecta
    position: 'relative',
  },
  checkIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.5rem', // Tamaño del icono de check
  },
  xIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.5rem', // Tamaño del icono de X
  },
}));

const MillionaireQuiz: React.FC = () => {
  const classes = useStyles();

  const questions: Question[] = [
    {
      question: '¿Cuál es la capital de Francia?',
      options: ['Londres', 'París', 'Berlín', 'Madrid'],
      correctOption: 'París',
    },
    {
      question: '¿Cuál es el océano más grande?',
      options: ['Atlántico', 'Índico', 'Antártico', 'Pacífico'],
      correctOption: 'Pacífico',
    },
    // Agrega más preguntas según sea necesario
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

  const handleOptionClick = (selectedOption: string) => {
    const isAnswerCorrect = selectedOption === questions[currentQuestionIndex].correctOption;

    if (isAnswerCorrect) {
      setScore(score + 1);
      playSound(correctSound);
    } else {
      playSound(incorrectSound);
    }

    setIsCorrect(isAnswerCorrect);

    // Mostrar la siguiente pregunta después de 2 segundos
    setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setShowResult(false); // Ocultar el resultado actual
        setIsCorrect(null); // Reiniciar la indicación de respuesta correcta
      } else {
        setShowResult(true);
      }
    }, 2000); // 2000 milisegundos (2 segundos)
  };

  const playSound = (sound: string) => {
    const audio = new Audio(sound);
    audio.play();
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setShowResult(false);
    setIsCorrect(null);
  };

  return (
    <div className={`h-screen ${classes.millionaireQuiz}`}>
      {!showResult ? (
        <div className={classes.questionContainer}>

          <div className='flex justify-center'>
            <img src="https://replicate.delivery/pbxt/HessJf6034rIdkcbCPfl3bB8bWsyTuOzaZSmCeCXUxxeOONPC/output_1.png"
              style={{ maxHeight: "200px" }}
              alt="" />
          </div>

          <p className={`${classes.questionText} my-4`}>
            {questions[currentQuestionIndex].question}
          </p>
          <div className='flex flex-wrap'>
            {questions[currentQuestionIndex].options.map((option, index) => (
              <div className='w-1/2 py-2 px-4'>
                <div
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={` ${classes.option} ${isCorrect !== null &&
                    (isCorrect ? (option === questions[currentQuestionIndex].correctOption ? 'correct' : '') : 'incorrect')
                    }`}
                >
                  {option} {isCorrect !== null && (isCorrect ? (option === questions[currentQuestionIndex].correctOption ? '✔️' : '') : '❌')}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={`${classes.resultContainer}`}>
          <h2>¡Quiz completado!</h2>
          <p>Tu puntuación: {score}/{questions.length}</p>
          <button onClick={resetQuiz} className={classes.resetButton}>
            Reiniciar
          </button>
        </div>
      )}
    </div>
  );
};

export default MillionaireQuiz;
