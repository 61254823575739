
export const PROGRAM_SET_SELECTED_PROGRAM = '[PROGRAM] SET_SELECTED_PROGRAM';
export const PROGRAM_CLEAR_SELECTED_PROGRAM = '[PROGRAM] CLEAR_SELECTED_PROGRAM';

export function setSelectedProgramAction(token) {
    return {
        type: PROGRAM_SET_SELECTED_PROGRAM,
        payload: token
    }
}

export function clearSelectedProgramAction(token) {
    return {
        type: PROGRAM_CLEAR_SELECTED_PROGRAM
    }
}