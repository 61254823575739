
export const AUTH_LOGOUT_TYPE = 'AUTH_LOGOUT_TYPE';
export const AUTH_LOGIN_TYPE = '[AUTH] LOGIN_SUCCES';

export function loginSuccessAction(token) {
    return {
        type: AUTH_LOGIN_TYPE,
        payload: token
    }
}

export function logoutSuccessAction(token) {
    return {
        type: AUTH_LOGOUT_TYPE,
        payload: token
    }
}

