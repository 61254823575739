import {
    createStore, combineReducers, applyMiddleware
} from 'redux';

import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import AuthReducer from './reducers/auth.reducer';

import CourseDetailReducer from '../pages/course-detail/store/reducers';
import programReducer from './reducers/program.reducer';
import appReducer from './reducers/app.reducer';

const middleware = process.env.NODE_ENV === "development"
    ? composeWithDevTools(
        applyMiddleware(createLogger(
            {
                level: {
                    prevState: false,
                    // action: false,
                    nextState: false

                },
                // diff: true
            }
        ))
        // other store enhancers if any
    )
    : undefined;



export default createStore(
    combineReducers({
        auth: AuthReducer,
        program: programReducer,
        app: appReducer,
        courseDetail: CourseDetailReducer
    }),
    {},
    middleware
);
