import React from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router";

export function VimeoPlayerComponent(props) {

    let { url } = useParams();


    return <div className="bg-gray-800">

        <div className="h-screen">
            <ReactPlayer
                playing={true}
                url={"https://vimeo.com/" + url}
                width="100%"
                height="100%"
                onPause={() => {}}
                controls={true}
            />
        </div>

    </div>
}