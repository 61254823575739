
export const SET_PLAYING_CONTENT = '[APP] SET_PLAYING_CONTENT';
export const SET_PLAYER_LEFT_SIDE_STATE = '[APP] SET_PLAYER_LEFT_SIDE_STATE';

export function updatePlayingContentAction(state) {
    return {
        type: SET_PLAYING_CONTENT,
        payload: state
    }
}

export function updatePlayerLeftSideStateAction(state) {
    return {
        type: SET_PLAYER_LEFT_SIDE_STATE,
        payload: state
    }
}
