
import { AUTH_LOGOUT_TYPE, AUTH_LOGIN_TYPE } from '../actions/authActions';
import jwtDecoder from 'jwt-decode';

const DEFAULT_STATE = {
    token: null,
    user: null
}

const authReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {

        case AUTH_LOGOUT_TYPE:
            return {
                ...state,
                token: null,
                user: null
            }

        case AUTH_LOGIN_TYPE:
            return {
                ...state,
                token: action.payload,
                user: jwtDecoder(action.payload)
            }

        default:
            return state;
    }
}

export default authReducer;