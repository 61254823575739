
import { COURSE_DETAIL_SAVE_COURSE_DATA } from './actions';

const DEFAULT_STATE = {
    courseData: null
}

const courseDetailReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case COURSE_DETAIL_SAVE_COURSE_DATA:
            return {
                ...state,
                courseData: action.payload
            }

        default:
            return state;
    }
}

export default courseDetailReducer;