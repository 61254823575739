import { SESSION_STORAGE_KEYS } from "@constants/sessionStorageKeys";
import { PROGRAM_CLEAR_SELECTED_PROGRAM, PROGRAM_SET_SELECTED_PROGRAM } from "../actions/programActions";


const DEFAULT_STATE = {
    name: null,
    header: null,
    /**
     * @type Array<{ text: string, url: string }>
     */
    menuNavigation: null,
    avatarMenuButton: null
};

const programReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {

        case PROGRAM_SET_SELECTED_PROGRAM:
            sessionStorage.setItem(SESSION_STORAGE_KEYS.CHOSEN_PROGRAM, action.payload.key);
            return action.payload;

        case PROGRAM_CLEAR_SELECTED_PROGRAM:
            sessionStorage.removeItem(SESSION_STORAGE_KEYS.CHOSEN_PROGRAM);
            return null;

        default:
            return state;
    }
}

export default programReducer;