import React, { Suspense, lazy } from 'react';

import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import './App.css';
import { LoadingComponent } from '@components/loading';
import { VimeoPlayerComponent } from './pages/emprender-preview/video-preview';
import { PresentationComponent } from './pages/emprender-preview/geneally-preview';
import MillionaireQuiz from './pages/quiz/MillionaireQuizComponent';

const MainLayoutComponent = lazy(() => import('./Layouts/MainLayout'));
const CourseLayoutComponent = lazy(() => import('./Layouts/courseLayout'));
const PageNoFound = lazy(() => import('./pages/404-pages/pageNotFound'));
const LoginComponent = lazy(() => import('./pages/login/login'));
const ForgotPasswordComponent = lazy(() => import('./pages/forgot-password/forgot-password'));
const SupportRequestComponent = lazy(() => import('./pages/support-request/support-request'));
const TermsConditionsComponent = lazy(() => import('./pages/terms-conditions/terms-conditions'));
const DoradoGameConditionsComponent = lazy(() => import('./pages/game-conditions/game-conditions'));
const EmprenderGameConditionsComponent = lazy(() => import('./pages/game-conditions/game-conditions-emprender'));
const EmprenderPreviewComponent = lazy(() => import('./pages/emprender-preview/preview'));

function App() {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <BrowserRouter>
        <Switch>
          <Redirect exact path="/" to="/main" />

          <Route path="/main" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <MainLayoutComponent {...props} />
            </Suspense>)} />

          <Route path="/course" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <CourseLayoutComponent {...props} />
            </Suspense>)} />

          <Route path="/login" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <LoginComponent {...props} />
            </Suspense>)} />

          <Route path="/forgot-password" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <ForgotPasswordComponent {...props} />
            </Suspense>)} />

          <Route path="/support" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <SupportRequestComponent {...props} />
            </Suspense>)} />

          <Route path="/terms-contidions" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <TermsConditionsComponent {...props} />
            </Suspense>)} />

          <Route path="/conditions/game/el-dorado" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <DoradoGameConditionsComponent {...props} />
            </Suspense>)} />

          <Route path="/conditions/game/emprender" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <EmprenderGameConditionsComponent {...props} />
            </Suspense>)} />

          <Route path="/new-password/:token" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <ForgotPasswordComponent {...props} />
            </Suspense>)} />

          <Route exact={true} path="/emprender-preview" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <EmprenderPreviewComponent {...props} />
            </Suspense>)} />

          <Route path="/emprender-preview/presentation/:url" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <PresentationComponent {...props} />

            </Suspense>)} /><Route path="/emprender-preview/video/:url" render={(props) => (
              <Suspense fallback={<LoadingComponent />}>
                <VimeoPlayerComponent {...props} />
              </Suspense>)} />

          <Route path="/quiz" render={(props) => <MillionaireQuiz></MillionaireQuiz> } />


          <Route path="/page404" render={(props) => (
            <Suspense fallback={<LoadingComponent />}>
              <PageNoFound {...props} />
            </Suspense>)} />

          <Redirect to="/page404" />

        </Switch>
      </BrowserRouter>

    </SnackbarProvider>

  );
}

export default App;
