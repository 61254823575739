import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const stylesSuspense: React.CSSProperties = {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export function LoadingComponent (){
    return (
        <div style={stylesSuspense}>
            <ClipLoader color={'#00CCCC'} size={150} speedMultiplier={1}></ClipLoader>
        </div>
    );
}